function removeUploadList()
{
    var doc=document.getElementsByClassName('ant-upload-list-item');
    for(var i=0;i<doc.length;i++)
    {
        doc[i].style.display = 'none';
    }
}

function removeListeners()
{
    
    try
    {
        var item = document.getElementsByTagName("nz-modal-container")[0];
        var clonedNode = item.cloneNode(true);
        item.parentNode.replaceChild(clonedNode, item);
    }
    catch(err){
            console.error(err);
    }
    
}