var spotifyPlayerID = "";
var playerPaused = false;
var player = null;
function InitSpotifyWebPlayer() {
  (async () => {
    const { Player } = await waitForSpotifyWebPlaybackSDKToLoad();
    console.log("The Web Playback SDK has loaded.");
    CreateSpotifyPlayer();
  })();

}

async function waitForSpotifyWebPlaybackSDKToLoad () {
  return new Promise(resolve => {
    if (window.Spotify) {
      resolve(window.Spotify);
    } else {
      window.onSpotifyWebPlaybackSDKReady = () => {
        resolve(window.Spotify);
      };
    }
  });
};

function CreateSpotifyPlayer() {

  console.log("create spotify player");
  var token = JSON.parse(localStorage.getItem("spotify_token"));
  player = new window.Spotify.Player({
    name: 'Cameline Spotify Web Player',
    getOAuthToken: cb => {
      cb(token.access_token);
    }
  });

  // Error handling
  player.addListener('initialization_error', ({
    message
  }) => {
    console.error(message);
  });
  player.addListener('authentication_error', ({
    message
  }) => {
    console.error(message);
  });
  player.addListener('account_error', ({
    message
  }) => {
    console.error(message);
  });
  player.addListener('playback_error', ({
    message
  }) => {
    console.error(message);
  });

  // Playback status updates
  player.addListener('player_state_changed', state => {
    if (state)
      playerPaused = state.paused;
  });

  // Ready
  player.addListener('ready', ({
    device_id
  }) => {
    console.log('Ready with Device ID', device_id);
    if (spotifyPlayerID == "")
      spotifyPlayerID = device_id;
  });

  // Not Ready
  player.addListener('not_ready', ({
    device_id
  }) => {
    console.log('Device ID has gone offline', device_id);
  });

  // Connect to the player!
  player.connect().then(success => {
    if (success) {
      console.log('The Web Playback SDK successfully connected to Spotify!');

    }
  });
}
